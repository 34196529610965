import React, { FC } from 'react';
import {
  createBrowserRouter,
  useNavigate,
  RouterProvider,
} from 'react-router-dom';
import { PageNotFound } from '@bestseller-bit/sales-and-invoicing.ui-elements.page-not-found';
import { CUSTOMIZED, FIXED } from '../customerSpecificAssortments/ShowCSA';
import ManageNotifications from '../notificationSettings/ManageNotifications';
import CustomerMainPage from '../_CustomerMainPage/CustomerMainPage';
import PathConstants from '../../../routes/pathConstants';

const Landing = React.lazy(
  () => import('../landingPage/app.show.landing.page')
);
const BusinessTradingPartnerDetails = React.lazy(() => import('../BTPShipTos'));
const BusinessTradingPartners = React.lazy(
  () => import('../../../pages/businessTradingPartners/BusinessTradingPartner')
);
const CustomerLookUp = React.lazy(
  () => import('../../../pages/customerLookup/CustomerLookup')
);
const CustomerSpecificAssortment = React.lazy(
  () => import('../customerSpecificAssortments/ShowCSA')
);
const CustomerSpecificAssortmentType = React.lazy(
  () => import('../customerSpecificAssortments/customerSelected/ShowCSAType')
);
const MyProfile = React.lazy(
  () => import('../../../pages/myProfile/app.show.myProfile.page')
);
const PackingInstructions = React.lazy(
  () => import('../customerSpecificPackingInstructions/app.show.CSPI.page')
);
const PlanMyRoute = React.lazy(
  () => import('../../../pages/planMyRoute/PlanMyRoute')
);
const Prospects = React.lazy(() => import('../../../pages/prospects'));
const Reminders = React.lazy(() => import('../customerReminders/overview'));
const ShipToDetails = React.lazy(
  () => import('../shipTo.info/ShowShipToInfoPage')
);
const ShipTos = React.lazy(() => import('../../../pages/shipTos/ShipTo'));
const Support = React.lazy(() => import('../support/app.show.support.page'));
const PhotoSamples = React.lazy(
  () => import('../../../pages/photoSamples/PhotoSample')
);
const DistributionSettings = React.lazy(
  () =>
    import(
      '../../../pages/customerSpecificDistributionSettings/CustomerSpecificDistributionSettings'
    )
);

const PUBLIC_URL = `${process.env.PUBLIC_URL}`;

const MainRouter: FC = () => {
  const router = createBrowserRouter(
    [
      {
        element: <CustomerMainPage />,
        children: [
          {
            element: <Landing />,
            path: PathConstants.HOME,
          },
          {
            element: <PageNotFoundComponent />,
            path: PathConstants.PAGE_NOT_FOUND,
          },
          {
            path: PathConstants.BUSINESS_TRADING_PARTNERS,
            element: <BusinessTradingPartners />,
          },
          {
            path: PathConstants.BUSINESS_TRADING_PARTNERS_DETAILS,
            element: <BusinessTradingPartnerDetails />,
          },
          {
            path: PathConstants.CUSTOMER_LOOKUP,
            element: <CustomerLookUp />,
          },
          {
            path: PathConstants.CUSTOMER_SPECIFIC_ASSORTMENT,
            element: <CustomerSpecificAssortment />,
          },
          {
            path: PathConstants.CUSTOMIZED_ASSORTMENT,
            element: (
              <CustomerSpecificAssortmentType assortmentType={CUSTOMIZED} />
            ),
          },
          {
            path: PathConstants.FIXED_ASSORTMENT,
            element: <CustomerSpecificAssortmentType assortmentType={FIXED} />,
          },
          {
            path: PathConstants.MY_PROFILE,
            element: <MyProfile />,
          },
          {
            path: PathConstants.PACKING_INSTRUCTIONS,
            element: <PackingInstructions />,
          },
          {
            path: PathConstants.PLAN_MY_ROUTE,
            element: <PlanMyRoute />,
          },
          {
            path: PathConstants.PROSPECTS,
            element: <Prospects />,
          },
          {
            path: PathConstants.REMINDERS,
            element: <Reminders />,
          },
          {
            path: PathConstants.SHIP_TO_DETAILS,
            element: <ShipToDetails />,
          },
          {
            path: PathConstants.SHIP_TOS,
            element: <ShipTos />,
          },
          {
            path: PathConstants.SUPPORT,
            element: <Support />,
          },
          {
            path: PathConstants.MANAGE_NOTIFICATIONS,
            element: <ManageNotifications />,
          },
          {
            path: PathConstants.PHOTO_SAMPLES,
            element: <PhotoSamples />,
          },
          {
            path: PathConstants.DISTRIBUTION_SETTINGS,
            element: <DistributionSettings />,
          },
        ],
      },
    ],
    {
      basename: PUBLIC_URL,
    }
  );

  return <RouterProvider router={router} />;
};

const PageNotFoundComponent: FC = () => {
  const navigate = useNavigate();

  const goToMainPage = () => {
    navigate(PathConstants.HOME);
  };

  return <PageNotFound goToMainPage={goToMainPage} />;
};

export default MainRouter;
