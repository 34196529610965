import { optionsType } from '../../application/components/SelectDropdown/types';
import { RelationType, ResultWithCount } from '../../queries/shared/types';
import { TimeInterval } from '../../utility/DateTime';
import { API } from './api/api';
import {
  Reminder,
  FindRemindersTypes,
  FindRemindersRelatedToInput,
  UpdateReminderInput,
  ReservedTime,
  EventInput,
  UpdateOutlookEventInput,
} from './types';

export class Service {
  private static reservedTime: ReservedTime;

  private static typeOptions: Array<optionsType> = [];

  static find = async (
    filter: FindRemindersRelatedToInput
  ): Promise<ResultWithCount<Reminder>> => {
    const result = await API.find(filter);
    return result;
  };

  static add = async (
    newItem: Reminder,
    relations: [RelationType],
    outlookEvent: EventInput
  ) => {
    const result = await API.add(newItem, relations, outlookEvent);
    return result;
  };

  static delete = async (publicId: string, outlookEventId: string) => {
    const result = await API.delete(publicId, outlookEventId);
    return result;
  };

  static update = async (
    publicId: string,
    outlookEventInput: UpdateOutlookEventInput,
    newItem?: Reminder,
    newRelations?: RelationType[]
  ) => {
    const input: UpdateReminderInput = {
      publicId,
      outlookEventInput,
    };
    if (newItem) input.reminder = newItem;
    if (newRelations) input.relations = newRelations;
    const result = await API.update(input);
    return result;
  };

  static findTypeOptions = async (filter: FindRemindersTypes) => {
    // TODO: improvement. Return typeOptions if available
    const result = await API.findTypeOptions(filter);
    Service.typeOptions = result.result;
    return result;
  };

  static getTypeOptions = () => Service.typeOptions;

  static getBusySlots = () => Service.reservedTime;

  static setBusySlots = (day: Date, slots?: Array<TimeInterval>) => {
    Service.reservedTime = {
      day,
      slots,
    };
  };
}
