import { StringMultiSelect } from '@bestseller-bit/sales-and-invoicing.input.string-multiselect';
import { SelectableValue } from '@bestseller-bit/sales-and-invoicing.input.string-multiselect/dist/string-multiselect';
import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { Brand } from '../../../../@types/branding/branding.types';
import { PhasePriceNotification } from './PhasePriceNotification';

type Props = {
  allPhasePrices: PhasePriceNotification[];
  setAllPhasePrices: Dispatch<SetStateAction<PhasePriceNotification[]>>;
  brandRows: Brand[];
};

const BrandMultiSelect: FC<Props> = ({
  allPhasePrices,
  setAllPhasePrices,
  brandRows,
}) => {
  const allBrandOptions: SelectableValue[] = brandRows.map((brand) => {
    return { value: brand.publicId, name: brand.name };
  });
  const [selectedValues, setSelectedValues] = useState<string[]>(
    allPhasePrices.map((elem) => elem.brand.publicId)
  );

  useEffect(() => {
    setSelectedValues(allPhasePrices.map((elem) => elem.brand.publicId));
  }, [allPhasePrices]);

  const handleUpdatedArray = (values: string[]) => {
    const newPhasePrices = [...allPhasePrices];
    const newBrand = brandRows.find((brand) => brand.publicId === values[0]);
    if (newBrand) {
      setAllPhasePrices([
        ...newPhasePrices,
        { brand: newBrand, productLines: [] },
      ]);
    }
  };

  return (
    <StringMultiSelect
      label="Brands"
      selectionState={[selectedValues, setSelectedValues]}
      allSelectableItems={allBrandOptions}
      handleUpdatedArray={(values) => handleUpdatedArray(values)}
    />
  );
};

export default BrandMultiSelect;
