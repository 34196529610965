import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  SxProps,
  Typography,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { optionsType, SelectControlType } from './types';
import { EMPTY_STRING, isEmpty } from '../../../utility/stringUtils';

export const ALL_OPTIONS: optionsType = { label: 'All', value: 'all' };
export const NONE_OPTION: optionsType = { label: 'None', value: 'none' };

export const renderLabel = (
  options: Array<optionsType>,
  value: string | null,
  sx?: { [k: string]: SxProps }
) => {
  const element: optionsType | undefined = [...options, ALL_OPTIONS].find(
    (e) => e.value === value
  );
  const labelRet: string = element ? element.label : EMPTY_STRING;
  return sx ? <Typography sx={sx}>{labelRet}</Typography> : labelRet;
};

const TransparentIcon = () => (
  <ArrowDropDownIcon sx={{ opacity: 0, height: 0, width: 0 }} />
);

export const createSelectControl = (props: SelectControlType) => {
  return <SelectFieldControl {...props} />;
};
export const SelectFieldControl = (props: SelectControlType) => {
  const {
    id,
    label,
    labelClass,
    formControlClass,
    menuItemClass,
    placeHolder,
    value,
    multiple = false,
    checkboxes = false,
    includeAllOption = false,
    includeNoneOption = false,
    errors,
    helper,
    onChange,
    validationPromise,
    options,
    rest,
    disableUnderline = false,
    disabled,
    hideArrow = false,
    darkBackground = false,
    disablePortal = true,
    openDropDown = [],
    size,
    variant,
  } = props;
  const [helperText, setHelperText] = React.useState<string>(
    Array.isArray(errors) ? errors[0] : helper || ''
  );
  const [, setShowOpenDropDown] = openDropDown;

  const fw = !(rest && rest.fullWidth === false);
  const opts = includeAllOption
    ? includeNoneOption
      ? [ALL_OPTIONS, NONE_OPTION, ...options]
      : [ALL_OPTIONS, ...options]
    : includeNoneOption
    ? [NONE_OPTION, ...options]
    : options;
  React.useEffect(() => {
    function resolve(promise: Promise<any>) {
      promise
        // eslint-disable-next-line func-names
        .then(function () {
          setHelperText('');
        })
        // eslint-disable-next-line func-names
        .catch(function (e: any) {
          if (Array.isArray(e.errors)) setHelperText(e.errors[0]);
        });
    }
    if (validationPromise) {
      resolve(validationPromise);
    } else if (helper !== undefined) {
      setHelperText(helper);
    }
    // eslint-disable-next-line
  }, [value]);

  const formControlProps = {
    ...(formControlClass && { sx: formControlClass }),
  };

  return (
    <FormControl
      fullWidth={fw}
      error={Array.isArray(errors)}
      {...formControlProps}
      size="small"
    >
      {label && label !== ' ' && <InputLabel>{label}</InputLabel>}
      <Select
        MenuProps={{ disablePortal }} // keep options as a child component to keep parent reference
        id={id}
        multiple={multiple}
        size={size}
        variant={variant}
        disableUnderline={disableUnderline}
        disabled={disabled}
        label={label}
        value={value || EMPTY_STRING}
        error={!!helperText}
        IconComponent={hideArrow ? TransparentIcon : ArrowDropDownIcon}
        sx={{
          textAlign: hideArrow ? 'center' : 'start',
          paddingLeft: 1.5,
          fontSize: 16,
          margin: '0 !important',
          color: darkBackground ? 'white' : 'primary.main',
          '.MuiSelect-select': {
            px: 3,
            py: 1.5,
          },
        }}
        onChange={(e) => {
          let val: string | string[] = e.target.value as string;
          if (multiple && includeAllOption) {
            const values = e.target.value as string[];
            const allWasSelected =
              value !== null ? value.length === opts.length : false;
            const allIsSelected = values.indexOf(ALL_OPTIONS.value) > -1;
            val = allIsSelected
              ? allWasSelected
                ? values.filter((o: string) => o !== ALL_OPTIONS.value)
                : opts.map((event: optionsType) => event.value)
              : allWasSelected
              ? []
              : values;
          }
          onChange(val);
        }}
        renderValue={
          Array.isArray(value)
            ? (selected: string[]) =>
                selected.map((v: string) => renderLabel(options, v)).join(', ')
            : (selected: string) =>
                placeHolder && value !== null && isEmpty(value) ? (
                  <Typography sx={labelClass}>{placeHolder}</Typography>
                ) : (
                  renderLabel(options, selected, menuItemClass || EMPTY_STRING)
                )
        }
        {...rest}
        onOpen={
          setShowOpenDropDown ? () => setShowOpenDropDown(true) : undefined
        }
        onClose={
          setShowOpenDropDown ? () => setShowOpenDropDown(false) : undefined
        }
      >
        {placeHolder && (
          <MenuItem value={EMPTY_STRING} disabled>
            <Typography sx={labelClass}>{placeHolder}</Typography>
          </MenuItem>
        )}
        {opts.map((op: optionsType) => (
          <MenuItem
            key={op.value}
            value={op.value}
            sx={{
              fontSize: 2,
            }}
          >
            {checkboxes && (
              <Checkbox
                checked={
                  value !== null
                    ? value.indexOf(op.value) > -1
                    : op.value === value
                }
              />
            )}
            <Typography
              key={`${id}-option-${op.value}`}
              sx={op.style ? op.style : menuItemClass || labelClass}
            >
              {op.label}
            </Typography>
          </MenuItem>
        ))}
      </Select>
      {Array.isArray(errors)
        ? errors.map((e) => <FormHelperText key={e}>{e}</FormHelperText>)
        : helperText && (
            <FormHelperText
              error
              sx={rest && rest.sx ? rest.sx : EMPTY_STRING}
              key={helperText}
            >
              {helperText}
            </FormHelperText>
          )}
    </FormControl>
  );
};
