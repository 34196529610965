/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  defaultDataIdFromObject,
  gql,
  split,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { WebSocketLink } from '@apollo/client/link/ws';
import { OperationDefinitionNode } from 'graphql';
import cfg from '../config/config';
import {
  getAndAddAuthenticationRetryCount,
  handleAuthenticationRequest,
  tokenIsAboutToExpire,
} from './Authorization';
import { apolloApiErrorHandler } from './ApolloErrorHandler';
import { QueryIdToken } from './__generated__/QueryIdToken';

// TODO: We should remove all this and use the one on Bit, but before that we need to remove all the service and api folders in Salestool UI, because we cant do `apolloClient.mutate`, instead we need to use the hooks such as `useMutation` and `useQuery`
const updatedIdTokenThroughGraphQL = async () => {
  const queryResponse = await apolloClient.query<QueryIdToken>({
    query: QUERY_ID_TOKEN,
  });
  const newIdToken = queryResponse.data.idToken;
  if (newIdToken) {
    return newIdToken;
  }

  return null;
};

const QUERY_ID_TOKEN = gql`
  query QueryIdToken {
    idToken
  }
`;

const acquireIdToken = async (refetch?: boolean) => {
  const currentIdToken = localStorage.getItem('id_token');
  const currentAuthorizationCode = localStorage.getItem('authorization_code');

  if (!currentIdToken || !currentAuthorizationCode || refetch) {
    handleAuthenticationRequest();
    return '';
  }

  if (tokenIsAboutToExpire(currentIdToken)) {
    try {
      const newToken = await updatedIdTokenThroughGraphQL();

      if (newToken) {
        localStorage.setItem('id_token', newToken);
        return newToken;
      }
    } catch (e) {
      console.log('Could not fetch token silently', e);
    }

    handleAuthenticationRequest();
  }

  localStorage.removeItem('auth_error');
  return currentIdToken;
};

const httpLink = new HttpLink({
  uri: `${cfg.getConfig().bffUrl}/graphql`,
});

const httpsharedLink = new HttpLink({
  uri: `${cfg.getConfig().sharedBffUrl}/graphql`,
});

const httpjavaLink = new HttpLink({
  uri: `${cfg.getConfig().javaBffUrl}/graphql`,
});

const wsJavaLink = new WebSocketLink({
  uri: `${cfg.getConfig().javaSubscriptionUrl}/subscriptions`,
  options: {
    reconnect: true,
    minTimeout: 60000,
    timeout: 60000,
    inactivityTimeout: 60000,
    reconnectionAttempts: 5,
    lazy: true,
    connectionParams: async () => {
      const token = await acquireIdToken();
      return {
        headers: {
          authorization: token,
        },
      };
    },
  },
});

const wsSharedLink = new WebSocketLink({
  uri: `${cfg.getConfig().sharedSubscriptionUrl}/subscriptions`,
  options: {
    reconnect: true,
    minTimeout: 60000,
    timeout: 60000,
    inactivityTimeout: 60000,
    reconnectionAttempts: 5,
    lazy: true,
    connectionParams: async () => {
      const token = await acquireIdToken();
      return {
        headers: {
          authorization: token,
        },
      };
    },
  },
});

const wsLinkSplit = ApolloLink.split(
  (operation) => {
    return operation.getContext().clientName === 'shared';
  },
  wsSharedLink,
  wsJavaLink
);

const apolloErrorLink = onError(({ graphQLErrors, networkError }) => {
  let reloadingToken = false;
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) => {
      // This a MS error code. It means that the authorization_code is already used, we need a new one
      // ERROR CODES: AADSTS500112, AADSTS54005, AADSTS70008
      if (message.includes('AADSTS')) {
        reloadingToken = true;
        localStorage.setItem('auth_error', message);
        getAndAddAuthenticationRetryCount();
        acquireIdToken(true);
      }
      console.error(`[GraphQL error]: ${message}`);
    });
  }
  if (networkError) {
    console.error(`[Network error]: ${networkError}`);
    const [statusCode, message] = apolloApiErrorHandler(networkError);
    if (!reloadingToken && statusCode > 400 && statusCode < 500) {
      localStorage.setItem('auth_error', message);
      getAndAddAuthenticationRetryCount();
      acquireIdToken(true);
    }
  }
});

const authLink = setContext(async (request, { headers }) => {
  let idToken: string;

  // If we are refreshing the token, then we need to use the local storage token, or else we will go in an infinite loop
  if (
    (QUERY_ID_TOKEN.definitions[0] as OperationDefinitionNode).name?.value ===
    request.operationName
  ) {
    const idTokenInLocalStorage = localStorage.getItem('id_token');
    if (idTokenInLocalStorage) {
      idToken = idTokenInLocalStorage;
    } else {
      idToken = await acquireIdToken(true);
    }
  } else {
    idToken = await acquireIdToken();
  }

  const CurrentHeaders = {
    authorization: `Bearer ${idToken}`,
    authorization_code: localStorage.getItem('authorization_code'),
    redirect_uri: localStorage.getItem('redirect_uri'),
  };

  return {
    ...request.context,
    headers: {
      ...headers,
      ...CurrentHeaders,
    },
  };
});

const httpLinkSplit = ApolloLink.split(
  (operation) => {
    return operation.getContext().clientName === 'shared';
  },
  httpsharedLink,
  ApolloLink.split(
    (operation) => {
      return operation.getContext().clientName === 'java';
    },
    httpjavaLink,
    httpLink
  )
);

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLinkSplit,
  httpLinkSplit
);

const link = apolloErrorLink.concat(authLink).concat(splitLink);

export const apolloClient = new ApolloClient({
  link,
  cache: new InMemoryCache({
    // This function is used to generate a unique identifier for every object in the cache, in our case we use the publicId
    dataIdFromObject(responseObject) {
      if (responseObject.__typename && responseObject.publicId) {
        return `${responseObject.__typename}_${responseObject.publicId}`;
      }
      return defaultDataIdFromObject(responseObject);
    },
  }),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
    },
    query: {
      fetchPolicy: 'network-only',
    },
    mutate: {
      fetchPolicy: 'network-only',
    },
  },
});
