import {
  addItemQuery,
  findQuery,
  deleteItemQuery,
  updateItemQuery,
  findTypesQuery,
} from '../../../queries/app/reminders/app.query';
import {
  addItemInterface,
  deleteItemInterface,
  findReminderTypesInterface,
  getQueryResultInterface,
  updateItemInterface,
} from '../../../queries/app/reminders/app.query.result.interface';
import { RelationType, ResultWithCount } from '../../../queries/shared/types';
import { mutation, query } from '../operations';
import {
  Reminder,
  FindRemindersTypes,
  FindRemindersRelatedToInput,
  UpdateReminderInput,
  EventInput,
} from '../types';

export class API {
  static find = async (
    filter: FindRemindersRelatedToInput
  ): Promise<ResultWithCount<Reminder>> => {
    const result: getQueryResultInterface = await query(findQuery, {
      ...filter,
    });
    return result.findReminders;
  };

  static add = async (
    newItem: Reminder,
    relations: [RelationType],
    outlookEvent: EventInput
  ) => {
    const result: addItemInterface = await mutation(
      addItemQuery,
      {
        input: {
          reminder: newItem,
          relations,
          outlookEvent,
        },
      }
      // {
      //   refetchQueries: ['findReminders'],
      // }
    );
    return result.addReminder;
  };

  static update = async (input: UpdateReminderInput) => {
    const result: updateItemInterface = await mutation(
      updateItemQuery,
      {
        input,
      }
      // {
      //   refetchQueries: ['findReminders'],
      // }
    );
    return result.updateReminder;
  };

  static delete = async (publicId: string, outlookEventId: string) => {
    const result: deleteItemInterface = await mutation(
      deleteItemQuery,
      {
        input: {
          publicId,
          outlookEventId,
        },
      }
      // {
      //   refetchQueries: ['findReminders'],
      // }
    );
    return result.deleteReminder;
  };

  static findTypeOptions = async (filter: FindRemindersTypes) => {
    const result: findReminderTypesInterface = await query(findTypesQuery, {
      ...filter,
    });
    return result.findReminderTypes;
  };
}
