import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Typography,
  Grid,
  FormHelperText,
  SelectChangeEvent,
} from '@mui/material';
import { ValidationError } from 'yup';
import { useFeedbackContext } from '@bestseller-bit/sales-and-invoicing.ui-elements.feedback';
import { CustomDialog } from '@bestseller-bit/sales-and-invoicing.ui-elements.dialog';
import { gql, useMutation } from '@apollo/client';
import { rootStateInterface } from '../../../../reducers';
import addExternalContactToCallListService from '../../../../services/mutations/add.external.contact.to.call.list/bestone.add.external.contact.to.call.list.services';
import {
  SHOW_CALL_LIST,
  NEW_EXTERNAL_CONTACT_FOR_CALL_LIST,
  RESET_NEW_EXTERNAL_CONTACT,
} from '../../../../constants/app/app.show.btp.mycustomers.page.constants';
import { updateBTPCallList } from '../call.list/call.list.utils/call.list.utils';
import { createOrUpdatePrimaryPhoneService } from '../../../../services/mutations/set.primary.phone.number/set.primary.phone.number.services';
import { ContactRelationType } from '../../../../constants/shared/mutation.constants/create.contact.constants';
import { UpdatableCallList } from '../../../../services/shared/call.list/interfaces';
import { store } from '../../../../middleware/store';
import {
  contactPhoneListValidation,
  nameSchema,
} from '../../../../validation/validation';
import {
  createExternalContact,
  createExternalContactVariables,
  createExternalContact_CREATE_CONTACT_result,
} from './__generated__/createExternalContact';

import { NewContactPhone } from '../../../../__generated__/globalTypes';
import { getAllActiveCountries_findByCountryColumns_result } from '../../../app/_MainApp/__generated__/getAllActiveCountries';

type ComponentProps = {
  openDialogState: [boolean, React.Dispatch<React.SetStateAction<boolean>>];
};

export const CreateExternalContact: FC<ComponentProps> = ({
  openDialogState,
}) => {
  const { newExternalContact, countries, currentUserQueryId } = useSelector(
    (state: rootStateInterface) => state.application.shared.bestoneCommon
  );
  const { addErrorMessage } = useFeedbackContext();
  const [open, setOpen] = openDialogState;
  const [nameValue, setNameValue] = useState<string>('');
  const [nameErrorMsg, setNameErrorMsg] = useState<string>();
  const [phoneValidation, setPhoneValidation] = useState<(string | string[])[]>(
    ['VALID', [''], ['']]
  );
  const [phoneValue, setPhoneValue] = useState<NewContactPhone[]>([]);

  const [countryCode, setCountryCode] = useState<NewContactPhone[]>([]);
  const [noteValue, setNoteValue] = useState('');
  const [allowToSave, setAllowToSave] = useState(false);
  const [contactPhoneList, setContactPhoneList] = useState<NewContactPhone[]>(
    []
  );
  const [rawContact, setRawContact] = useState<
    createExternalContact | undefined
  >();
  const dispatch = useDispatch();

  const [createExternalContactConnected] = useMutation<
    createExternalContact,
    createExternalContactVariables
  >(createExternalContactMutation, {
    onCompleted: (data) => {
      setRawContact(data);
      dispatch({ type: NEW_EXTERNAL_CONTACT_FOR_CALL_LIST, payload: data });
    },
    onError: (e) => addErrorMessage(e.message, createExternalContactMutation),
  });

  useEffect(() => {
    if (newExternalContact !== '') {
      const updateParams: UpdatableCallList = {
        updatableCallList: [
          {
            ownerPublicId: newExternalContact,
            note: noteValue,
          },
        ],
      };
      addExternalContactToCallListService(updateParams)
        .then((result) =>
          store.dispatch({ type: SHOW_CALL_LIST, payload: result })
        )
        .then(() => store.dispatch({ type: RESET_NEW_EXTERNAL_CONTACT }));
    }
  }, [newExternalContact, noteValue, dispatch]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleCreateContact = () => {
    const createExternalContactqueryParams: createExternalContactVariables = {
      publicId: currentUserQueryId,
      contactRelationType: ContactRelationType.USER,
      isMyContact: false,
      contactType: '',
      isPrimary: false,
      newContact: {
        contactName: nameValue,
        contactPhoneList,
        contactEmailAddres: '',
      },
    };
    createExternalContactConnected({
      variables: createExternalContactqueryParams,
    });
    setOpen(false);
  };

  const handlePhoneChange = (event: React.ChangeEvent<{ value: string }>) => {
    const phoneNumber = event.target.value.trim();
    const newPhoneArray = [...phoneValue];
    newPhoneArray[0] = {
      phoneNumber,
      countryPublicId: countryCode[0].countryPublicId,
    };
    setPhoneValue(newPhoneArray);
    const newPhoneListArray: NewContactPhone[] = [...contactPhoneList];
    newPhoneListArray[0] = {
      countryPublicId: contactPhoneList[0]
        ? contactPhoneList[0].countryPublicId
        : '',
      phoneNumber,
    };
    setContactPhoneList(newPhoneListArray);
  };

  const handleCountryChange = (event: SelectChangeEvent<{ value: string }>) => {
    const newCountryArray = [...countryCode];
    newCountryArray[0] = {
      phoneNumber: phoneValue[0] ? phoneValue[0].phoneNumber : '',
      countryPublicId: event.target.value ? event.target.value.toString() : '',
    };
    setCountryCode(newCountryArray);
    const newPhoneListArray = [...phoneValue];
    newPhoneListArray[0] = {
      countryPublicId: event.target.value ? event.target.value.toString() : '',
      phoneNumber: contactPhoneList[0] ? contactPhoneList[0].phoneNumber : '',
    };
    setContactPhoneList(newPhoneListArray);
  };

  useEffect(() => {
    if (
      contactPhoneList[0]?.countryPublicId === '' &&
      contactPhoneList[0]?.phoneNumber === undefined
    )
      setPhoneValidation(['VALID', [''], ['']]);
    else setPhoneValidation(contactPhoneListValidation(contactPhoneList));
  }, [contactPhoneList]);

  useEffect(() => {
    try {
      nameSchema.validateSync({ name: nameValue });
      setNameErrorMsg('');
    } catch (error) {
      if (error instanceof ValidationError) setNameErrorMsg(error.message);
    }
  }, [nameValue]);

  useEffect(() => {
    if (
      nameValue !== '' &&
      phoneValidation[0] !== 'INVALID' &&
      contactPhoneList.length > 0
    ) {
      setAllowToSave(true);
    } else {
      setAllowToSave(false);
    }
  }, [nameValue, phoneValidation, contactPhoneList]);

  useEffect(() => {
    if (open === false) {
      setNameValue('');
      setNameErrorMsg('');
      setCountryCode([]);
      setPhoneValue([]);
      setAllowToSave(false);
      setPhoneValidation(['INVALID', [''], ['']]);
    }
  }, [open]);

  useEffect(() => {
    const contactFull: createExternalContact_CREATE_CONTACT_result | undefined =
      rawContact !== undefined &&
      rawContact.CREATE_CONTACT &&
      rawContact.CREATE_CONTACT.result
        ? rawContact.CREATE_CONTACT.result[0]
        : undefined;
    const contactID: string = contactFull ? contactFull.contactPublicId : '';
    const contactPhoneId: string =
      contactFull &&
      contactFull.contactPhoneList &&
      contactFull.contactPhoneList[0]
        ? contactFull.contactPhoneList[0].publicId
        : '';
    if (contactID !== '' && contactPhoneId !== '') {
      const primaryPhoneProps = {
        contactPublicId: contactID,
        contactPhonePublicId: contactPhoneId,
      };
      createOrUpdatePrimaryPhoneService(primaryPhoneProps).then(() =>
        updateBTPCallList(dispatch)
      );
    }
  }, [rawContact, currentUserQueryId, dispatch]);

  return (
    <CustomDialog
      maxWidth="sm"
      minHeight="auto"
      openState={[open, setOpen]}
      onClose={handleClose}
      title="Create new external contact"
      buttons={{
        buttons: [
          {
            label: 'Save',
            onClick: handleCreateContact,
            disabled: allowToSave !== true,
          },
          {
            label: 'Cancel',
            onClick: handleClose,
          },
        ],
      }}
    >
      <TextField
        error={!!nameErrorMsg && nameValue === ''}
        onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
          setNameValue(event.target.value)
        }
        helperText={nameErrorMsg}
        autoFocus
        margin="dense"
        id="Contact_Name"
        label="Contact Name"
        type="text"
        fullWidth
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setNameValue(event.target.value)
        }
        sx={{ marginBottom: 5 }}
      />

      <Grid container alignItems="flex-start" pt={2}>
        <Grid item xs={12} sm={4}>
          <FormControl
            fullWidth
            error={
              !!(
                phoneValidation[2][0] === 'Select country code' ||
                phoneValidation[2][0] === 'Select -None- when phone is empty'
              )
            }
          >
            <InputLabel>Country code</InputLabel>
            <Select
              label="Country code"
              value={contactPhoneList[0]?.countryPublicId || ''}
              onChange={(e: SelectChangeEvent<{ value: string }>) =>
                handleCountryChange(e)
              }
            >
              {Array.isArray(countries) &&
                countries.map(
                  (item: getAllActiveCountries_findByCountryColumns_result) => (
                    <MenuItem
                      key={item.name}
                      sx={{ fontsize: 'body1' }}
                      value={item.countryIsoAlpha3}
                    >
                      <Typography sx={{ fontsize: 'body1' }}>
                        {`${item.name}  :  ${item.intAccessCode}`}
                      </Typography>
                    </MenuItem>
                  )
                )}
            </Select>
            <FormHelperText>{phoneValidation[2][0]}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={1} />
        <Grid item xs={12} sm={7}>
          <TextField
            error={!!phoneValidation[1][0]}
            helperText={phoneValidation[1][0]}
            label="Contact Phone"
            type="text"
            sx={{ m: 0 }}
            fullWidth
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              handlePhoneChange(event)
            }
            disabled={
              !contactPhoneList[0] ||
              contactPhoneList[0]?.countryPublicId === ''
            }
          />
        </Grid>
      </Grid>

      <TextField
        label="Note"
        fullWidth
        multiline
        maxRows="4"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setNoteValue(event.target.value.toString())
        }
        variant="outlined"
        sx={{ marginTop: 10 }}
      />
    </CustomDialog>
  );
};

export default CreateExternalContact;

const createExternalContactMutation = gql`
  mutation createExternalContact(
    $publicId: String!
    $contactRelationType: ContactRelationType!
    $isMyContact: Boolean
    $contactType: String
    $isPrimary: Boolean
    $newContact: NewContact!
  ) {
    CREATE_CONTACT: createContactConnected(
      publicId: $publicId
      contactRelationType: $contactRelationType
      isMyContact: $isMyContact
      contactType: $contactType
      isPrimary: $isPrimary
      newContact: $newContact
    ) {
      queryCount
      result {
        contactPublicId
        contactName
        contactPhoneList {
          publicId
          countryPublicId
          countryCode
          phoneNumber
          isPrimaryPhone
          changedBy
          changedDate
        }
        contactEmailAddress
        isMyContact
        note
        contactTypePublicId
        contactTypeDisplayName
        contactType
        primary
        changedBy
        changedDate
      }
    }
  }
`;
