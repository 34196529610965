import React, { FC, ReactNode, Suspense, useContext, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom';
import {
  ApplicationShell,
  Environment,
} from '@bestseller-bit/sales-and-invoicing.bootstrapping.application-shell';
import { Loading } from '@bestseller-bit/frontend-community.components.loading';
import { gql, useQuery } from '@apollo/client';
import { useFeedbackContext } from '@bestseller-bit/sales-and-invoicing.ui-elements.feedback';
import { rootStateInterface } from '../../../reducers';
import ScrollTop from '../../Shared/_utilities/scroll.to.top.button/scroll.to.top.button';
import CallList from '../../Shared/_utilities/call.list/CallList';
import Impersonate from '../../Shared/_BestOneMainPage/bestone.main.impersonate';
import { getMarketService } from '../../../services/queries/organization/market/bestone.market.services';
import getBTPCallList from '../../../services/call.list/bestone.btp.call.list.services';
import {
  SHOW_CALL_LIST,
  SHOW_KEY_FIGURES,
} from '../../../constants/app/app.show.btp.mycustomers.page.constants';
import { getBrandingService } from '../../../services/queries/organization/branding/bestone.branding.services';
import {
  GET_BRANDING,
  GET_MARKET,
} from '../../../constants/shared/app.branding.constants';
import Notifications from '../../Shared/_utilities/notifications';
import { UserContext } from '../_MainApp/CurrentUserContainer';
import {
  getKeyFigures,
  getKeyFiguresVariables,
} from './__generated__/getKeyFigures';

const hostname = window && window.location && window.location.hostname;
const environment: Environment =
  hostname === 'localhost' ? 'dev' : (process.env.REACT_APP_ENV as Environment);

interface CustomerMainPageProps {
  children?: ReactNode;
}

const CustomerMainPage: FC<CustomerMainPageProps> = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    currentUser,
    currentQueryUserName,
    impersonating,
    currentUserQueryId,
  } = useSelector((state: rootStateInterface) => ({
    ...state.application.shared.bestoneMainPage,
    ...state.application.shared.bestoneCommon,
  }));
  const [figures, setFigures] = useState<getKeyFigures | undefined>();
  const [showFigures, setShowFigures] = useState(false);

  const { addErrorMessage } = useFeedbackContext();

  const { currency } = useContext(UserContext);

  useQuery<getKeyFigures, getKeyFiguresVariables>(GET_KEY_FIGURES, {
    variables: {
      salesPersonId: currentUserQueryId,
      currencyCode: currency!,
    },
    onCompleted: (result) => {
      dispatch({ type: SHOW_CALL_LIST, payload: getBTPCallList() });
      dispatch({ type: SHOW_KEY_FIGURES, payload: result });
      dispatch({ type: GET_BRANDING, payload: getBrandingService() });
      dispatch({
        type: GET_MARKET,
        payload: getMarketService(),
      });
      setFigures(result);
      setShowFigures((prevState) => !prevState);
    },
    onError: (error) => {
      addErrorMessage(error.message, GET_KEY_FIGURES);
    },
  });

  const actionComponents = [
    <CallList key="callList" />,
    <Impersonate
      currentUser={currentUser}
      impersonating={impersonating}
      currentQueryUserName={currentQueryUserName}
      key="impersonate"
      keyFiguresData={figures}
      showFigures={[showFigures, setShowFigures]}
    />,
  ];

  return (
    <ApplicationShell
      menuProps={{ navigate }}
      topbarProps={{
        applicationName: 'BestOne Sales',
        actionComponents,
        env: environment,
      }}
    >
      <div id="back-to-top-anchor" />
      <Suspense fallback={<Loading />}>
        {currentUser ? children || null : null}
        <Outlet />
      </Suspense>
      <ScrollTop />
      <Notifications />
    </ApplicationShell>
  );
};
export default CustomerMainPage;

const GET_KEY_FIGURES = gql`
  query getKeyFigures($salesPersonId: String!, $currencyCode: String!) {
    myBusinessTradingPartnersSimpleKeyFigures(
      salesPersonPublicId: $salesPersonId
      currencyCode: $currencyCode
    ) {
      queryCount
      result {
        salesPersonPublicId
        indexMonth
        financialIndexYearToDate
        financialTotalYearToDate
      }
    }
  }
`;
